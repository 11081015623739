// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.service-container{
    display: flex;
    align-items: center;
    flex: 0 0 40%; 
    color:white;
    justify-content: center;
    h1{
        font-size: 50px;
        margin:0px;
    }
    h2{
        margin:0px;
        font-size: 32px;
    }
    p{
        margin:50px 0px;
        font-size:18px;
    }
}
.videoPlayer{
    flex: 0 0 40%; 
    width:500px;
    height:300px;
    margin:50px 70px;
    color:white;
    .enrollmentSteps{
        h2{
            font-size: 30px;
        }
        li{
            font-size: 17px;
        }
    }
}
.subscribe-button-3d {
    padding: 12px 30px;
    margin:0px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background: linear-gradient(90deg, #ff6a00, #ee0979, #34ebba, #00a8ff, #ff6a00);
    background-size: 400%;
    border-radius: 50px;
    cursor: pointer; 
    animation: gradientAnimation 5s ease infinite;
    background-clip: padding-box; 
  }
  
  .subscribe-button-3d:hover {
    transform: translateY(-2px) scale(1.05); 
  }`, "",{"version":3,"sources":["webpack://./src/Components/Component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,WAAW;IACX,uBAAuB;IACvB;QACI,eAAe;QACf,UAAU;IACd;IACA;QACI,UAAU;QACV,eAAe;IACnB;IACA;QACI,eAAe;QACf,cAAc;IAClB;AACJ;AACA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,WAAW;IACX;QACI;YACI,eAAe;QACnB;QACA;YACI,eAAe;QACnB;IACJ;AACJ;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,+EAA+E;IAC/E,qBAAqB;IACrB,mBAAmB;IACnB,eAAe;IACf,6CAA6C;IAE7C,4BAA4B;EAC9B;;EAEA;IACE,uCAAuC;EACzC","sourcesContent":[".page{\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n}\n.service-container{\n    display: flex;\n    align-items: center;\n    flex: 0 0 40%; \n    color:white;\n    justify-content: center;\n    h1{\n        font-size: 50px;\n        margin:0px;\n    }\n    h2{\n        margin:0px;\n        font-size: 32px;\n    }\n    p{\n        margin:50px 0px;\n        font-size:18px;\n    }\n}\n.videoPlayer{\n    flex: 0 0 40%; \n    width:500px;\n    height:300px;\n    margin:50px 70px;\n    color:white;\n    .enrollmentSteps{\n        h2{\n            font-size: 30px;\n        }\n        li{\n            font-size: 17px;\n        }\n    }\n}\n.subscribe-button-3d {\n    padding: 12px 30px;\n    margin:0px;\n    font-size: 16px;\n    font-weight: bold;\n    color: white;\n    background: linear-gradient(90deg, #ff6a00, #ee0979, #34ebba, #00a8ff, #ff6a00);\n    background-size: 400%;\n    border-radius: 50px;\n    cursor: pointer; \n    animation: gradientAnimation 5s ease infinite; \n    -webkit-background-clip: padding-box;\n    background-clip: padding-box; \n  }\n  \n  .subscribe-button-3d:hover {\n    transform: translateY(-2px) scale(1.05); \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
