import React from 'react';
import './Testimonials.css';
import Data from './data/testimonial.json';

const Testimonials = () => {
  return (
    <div className="testimonial">
      <div className="section-title"> 
        <h2 className="text-white">Testimonials</h2>
      </div>
      <div className="ticker-wrapper">
        <div className="ticker-content">
          {Data.testimonials &&
            Data.testimonials.map((value, i) => (
              <div className="saas-testimonial-box" key={i}>
                <div className="icon-circle">
                  <img src={'/testimonial.png'} alt={value.name} className="circular-image" />
                </div>
                <div className="testimonial-header">
                  <p className="testimonial-text">
                    "{value.testimonial}"
                  </p>
                  <span className="rating">
                    {Array.from({ length: 5 }, (_, index) => (
                      <span key={index} className={index < value.rating ? 'star filled' : 'star'}>
                        {index < value.rating ? '★' : '☆'}
                      </span>
                    ))}
                  </span>                      
                  <h3 className="name">{value.name}</h3>
                  <p className="position">
                    {value.position}, {value.company}
                  </p>
                </div>
                
              </div>
            ))}
             
            

        </div>
        <div className="ticker-content">
          {Data.testimonials &&
            Data.testimonials.map((value, i) => (
              <div className="saas-testimonial-box" key={i}>
                <div className="icon-circle">
                  <img src={'/testimonial.png'} alt={value.name} className="circular-image" />
                </div>
                <div className="testimonial-header">
                  <p className="testimonial-text">
                    "{value.testimonial}"
                  </p>
                  <span className="rating">
                    {Array.from({ length: 5 }, (_, index) => (
                      <span key={index} className={index < value.rating ? 'star filled' : 'star'}>
                        {index < value.rating ? '★' : '☆'}
                      </span>
                    ))}
                  </span>                      
                  <h3 className="name">{value.name}</h3>
                  <p className="position">
                    {value.position}, {value.company}
                  </p>
                </div>
                
              </div>
            ))}
             
            

        </div>
      </div>
    </div>
  );
};

export default Testimonials;
