import React, { useState } from 'react';
import { Tabs, Tab, TabList } from 'react-tabs';  
import './PrepGuruMain.css';
import CreateSessionModal from './CreateSessionModal';

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('Upcoming');
  const [isOpen,setIsOpen] = useState(false);

  const handleTabSelect = (index) => {
    setActiveTab(index === 0 ? 'Upcoming' : 'Completed');
  };

  // Data for the tables (you can replace this with dynamic data)
  const upcomingData = [
    { x: '0111', y: 'Data Scientist', z: 'resume_link1' },
    { x: '0112', y: 'Machine Learning Engineer', z: 'resume_link2' },
    { x: '0113', y: 'Data Engineer', z: 'resume_link3' }
  ];

  const completedData = [
    { a: '0111', b: 'Data Scientist', c: 'resume1' },
    { a: '0122', b: 'Data Scientist', c: 'resume2'},
    { a: '0133', b: 'Data Scientist', c: 'resume3' }
  ];
  const openModal = () => {
    setIsOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
  };
 

  return (
    <div className='dashboard-container'>
      <h3>Your Personalized Preparation Bot</h3>
      <p>Create a personalized preparation session to support your upcoming interviews. Personalize your preparation with your resume, job description, career goals, and more. After each session, you’ll receive a comprehensive interview report.</p>
      <div className="prep-bot-status-tabs">
        <Tabs onSelect={handleTabSelect}>
          <TabList>
            <Tab>Upcoming</Tab>
            <Tab>Completed</Tab>
          </TabList>
        </Tabs>
      </div>
      <hr className='line' />
      <div>
        <button className='create' onClick={openModal}><strong>+</strong> Create a session</button>
      </div>
      {isOpen && <CreateSessionModal isOpen={isOpen} closeModal={closeModal}></CreateSessionModal>}

      {/* Conditionally Render Table based on the Active Tab */}
      {activeTab === 'Upcoming' && (
        <table>
          <thead>
            <tr>
              <th>Session ID</th>
              <th>Job</th>
              <th>Resume</th>
              <th>Scheduled Time</th>
            </tr>
          </thead>
          <tbody>
            {upcomingData.map((item, index) => (
              <tr key={index}>
                <td>{item.x}</td>
                <td>{item.y}</td>
                <td>{item.z}</td>
                <td>{"9:00AMIST - 10.00PMIST"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {activeTab === 'Completed' && (
        <table>
          <thead>
            <tr>
              <th>Session ID</th>
              <th>Job</th>
              <th>Resume</th>
              <th>Time</th>
              <th>Duration</th>
              <th>Score</th>
              <th>Report</th>
            </tr>
          </thead>
          <tbody>
            {completedData.map((item, index) => (
              <tr key={index}>
                <td>{item.a}</td>
                <td>{item.b}</td>
                <td>{item.c}</td>
                <td>{"9:00AMIST - 10.00PMIST"}</td>
                <td>{"45min:30sec"}</td>
                <td>{90}</td>
                <td>{"datascience_report"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Dashboard;
