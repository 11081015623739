// DashboardLayout.js
import React from 'react';
import MainPage from './MainPage';
import { Outlet } from 'react-router-dom';

const DashboardLayout = () => {
  return (
    <div>
      <MainPage />
      <Outlet /> 
    </div>
  );
};

export default DashboardLayout;
