import React, { useEffect, useRef, useState } from 'react';
import './Component.css';
import config from '../config.json';
 
 
const PrepGuru = () => {
  const videoRef = useRef(null);
 
  return (
    <div className="page">
        <div className="service-container">
            <div className="info-section">
                <h1>Prep-Guru</h1>
                <h2>Custom Coaching for Your Success!</h2>
                <p>Get personalized interview prep with Prep-Guru! Custom Tailored questions based on your job description and experience, instant feedback after each question to sharpen your responses, and boost your confidence. Prepare with Prep-Guru to enhance and hone your Interview skills.</p>
                <button class="button-3d btn-primary">Subscribe</button>
            </div>
        </div>
            <div className="videoPlayer">
                
                <video
                ref={videoRef}
                src={`${config.backendAPI}testvideo.mp4`}
                autoPlay
                loop
                muted
                playsInline
                controls
                style={{ width: '100%', borderRadius: '10px' }}
            ></video>
            <div className="enrollmentSteps">
                <h2>Steps to Enroll</h2>
                <ol> {/* Use <ol> for ordered list if you want numbers */}
                    <li>Click on Pricing details of your respective service. If not loggedIn page will redirect to login page.</li>
                    <li>Here, enter you gmail/mobile and enter otp provided to login.</li>
                    <li>Once you are in, Select your desired service and subscription plan.</li>
                    <li>That is all it takes. Take our help and suceed in your career.</li>
                </ol>
            </div>
       
        </div>
    </div>
 
  );
};
 
export default PrepGuru;