import React, { useState, useRef, useEffect } from 'react';
import './ResumeGuru.css';
import config from '../config.json';
import YouTube from 'react-youtube';

const ResumeGuru = () => {
  const videoRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const playerRef = useRef(null);
 

  const cards = [
    {
      title: 'Resume Guru',
      subtitle: 'Framesports',
      description: 'Framesports uses AI to analyze Rugby matches.',
      buttonText: 'Explore Case Study',
      time: 0, // time in seconds for this card
    },
    {
      title: 'JD Guru',
      subtitle: 'Immediately',
      description: 'How Immediately empowers companies with AI.',
      buttonText: 'Explore Case Study',
      time: 40, // time in seconds for this card
    },
    {
      title: 'Career Guru',
      subtitle: 'Health Research',
      description: 'AI-driven research for better understanding of proteins.',
      buttonText: 'Explore Case Study',
      time: 80, // time in seconds for this card
    },
  ];

  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % cards.length);
  };

  const prevSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + cards.length) % cards.length);
  };
  useEffect(() => {
    if (playerRef.current && cards[activeIndex]) {
      playerRef.current.seekTo(cards[activeIndex].time, true);
    }
  }, [activeIndex]);
 
  const onPlayerReady = (event) => {
    playerRef.current = event.target; // Store the player instance
  };
 


  return (
    <div className="resume-guru">
      <div className="fancy-carousel">
        <button className="carousel-button prev" onClick={prevSlide}>
          &lt;
        </button>
        <button className="carousel-button next" onClick={nextSlide}>
          &gt;
        </button>
        {cards.map((card, index) => {
          const position =
            index === activeIndex
              ? 'fancy-carousel__item--active'
              : index === (activeIndex + 1) % cards.length
              ? 'fancy-carousel__item--next'
              : 'fancy-carousel__item--prev';
          return (
            <div
              key={index}
              className={`fancy-carousel__item card${index} ${position}`}
            >
              <h1>{card.title}</h1>
              <img src={`card${index + 4}logo.webp`} alt="Logo" />
              <h4>{card.subtitle}</h4>
              <p>{card.description}</p>
              
              <button className="btn btn-primary">Subscribe</button>
            </div>
          );
        })}
      </div>
      <div className={`fancy-carousal-right-panel`}>
      <YouTube
      className={`video${activeIndex}`}
      videoId="c0m6yaGlZh4"
      opts={{
        height: '315',
        width: '560',
        playerVars: {
          autoplay: 1,
          controls: 1,
          mute: 1,
        },
      }}
      onReady={onPlayerReady}
    />
        </div>
      </div>
  );
};

export default ResumeGuru;
