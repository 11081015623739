import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faLightbulb } from '@fortawesome/free-regular-svg-icons';

const CourseCard = ({animationControl}) => {
  const cardRefs = useRef([]);
  const [activeCard, setActiveCard] = useState(1);
  const [isActive, setIsActive] = useState({
    bulb1: false,
    bulb2: false,
    bulb3: false,
    bulb4: false,
    bulb5: false,
    bulb6: false,
  });

  const handleClick = (bulb) => {
    setIsActive((prevState) => ({
      ...prevState,
      [bulb]: !prevState[bulb],
    }));
  };

  const handleCardChange = (num) => {
    setActiveCard(num);
    if (cardRefs.current[num - 1]) {
      cardRefs.current[num - 1].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  const preventScrollPropagation = (e) => {
    const container = e.target;
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const offsetHeight = container.offsetHeight;

    if ((scrollTop === 0 && e.deltaY < 0) || (scrollTop + offsetHeight >= scrollHeight && e.deltaY > 0)) {
      e.stopPropagation();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const cardIndex = Number(entry.target.getAttribute("data-index")) + 1;
            setActiveCard(cardIndex);
          }
        });
      },
      { threshold: 0.5 }
    );

    cardRefs.current.forEach((card) => {
      if (card) {
        observer.observe(card);
      }
    });

    return () => {
      cardRefs.current.forEach((card) => {
        if (card) {
          observer.unobserve(card);
        }
      });
    };
  }, []);

  // useEffect(() => {
  //   if(animationControl){
  //   const interval = setInterval(() => {
  //     setActiveCard((prevCard) => {
  //       const nextCard = prevCard === 6 ? 1 : prevCard + 1;
  //       handleCardChange(nextCard);
  //       return nextCard;
  //     });
  //   }, 5000); 
  //   return () => clearInterval(interval);
  // }
  // }, [animationControl]);

  return (
    <>
      <div className="number-list">
        {[1, 2, 3, 4, 5, 6].map((num) => (
          <button
            key={num}
            className={`number-item ${activeCard === num ? 'active' : ''}`}
            onClick={() => handleCardChange(num)}
          >
            {num < 10 ? `0${num}` : num}
          </button>
        ))}
      </div>
      <div className="subclass-card-container" onWheel={preventScrollPropagation}>
        {[...Array(6)].map((_, index) => {
          const cardNum = index + 1;
          return (
            <div
              className='overall-cards'
              ref={(el) => (cardRefs.current[index] = el)}
              data-index={index}
              key={index}
            >
              <div className="card-outline">
                <h1>{cardNum === 1 ? 'Bot Guru' : cardNum === 2 ? 'Prep Guru' : cardNum === 3 ? 'Mock Guru' : cardNum === 4 ? 'Resume Guru' : cardNum === 5 ? 'JD Guru' : 'Career Guru'}</h1>
                <p>
                  {cardNum === 1 && 'Get ready to ace any job interview with BotGuru! Whether you’re a doctor, lawyer, teacher, chef, software developer, customer support rep, sales rep, or bank teller, our powerful AI tools will help you shine. From fresh grads to career changers, we’ve got you covered!'}
                  {cardNum === 2 && 'Get personalized interview prep with Prep-Guru! Custom Tailored questions based on your job description and experience, instant feedback after each question to sharpen your responses, and boost your confidence. Prepare with Prep-Guru to enhance and hone your Interview skills.'}
                  {cardNum === 3 && 'Mock-Guru simulates dynamic, realistic interview sessions to sharpen your skills. After each mock interview, receive a detailed report to track your progress. Practice to perfection and tackle your job interviews with renewed confidence and energy!'}
                  {cardNum === 4 && 'Revamp your job applications with our AI Resume Builder! Create a standout resume tailored to your skills with customizable templates, expert tips, and instant feedback. Whether you’re an experienced professional or a fresh graduate, showcase your strengths and land your dream job. Start building your future today!'}
                  {cardNum === 5 && 'With our AI Job Description Generator! Create clear, engaging job descriptions in minutes. Customize templates, highlight essential skills, and ensure consistency.'}
                  {cardNum === 6 && 'Unlock your potential with personalized guidance designed for your goals. Whether you’re seeking new opportunities or aiming for a promotion, get expert tips on interviews, and skill development. With real-time feedback and actionable insights, our AI Career Coach empowers you to turn your career aspirations into reality.'}
                </p>
              </div>
              <div className={`sub-card sub${cardNum} ${isActive[`bulb${cardNum}`] ? 'active' : ''}`} style={{ position: 'relative' }}>
                <button
                  className={`icon1-button ${isActive[`bulb${cardNum}`] ? 'active' : ''}`}
                  onClick={() => handleClick(`bulb${cardNum}`)}
                >
                  <FontAwesomeIcon icon={faLightbulb} />
                </button>
                <img src={`card${cardNum}logo.webp`} alt="Logo" />
                <h1>{cardNum === 1 ? 'Empowering Job Seekers' : cardNum === 2 ? 'Custom Coaching' : cardNum === 3 ? 'Practice to perfection!' : cardNum === 4 ? 'Create Perfect Resume!' : cardNum === 5 ? 'Generate engaging job descriptions' : 'Personalized guidance!'}</h1>
                <button className="icon2-button" onClick={() => alert('Button clicked!')}>
                  <FontAwesomeIcon icon={faStar} />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CourseCard;
