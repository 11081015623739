// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container{

}
.header-container{
    display: flex;
    align-items: center;
    width:100%;
    height:60px;
    background: linear-gradient(45deg,  #2b1d4d,#2b1d4d);
    .header-right-container{
        display: flex;
        align-items: center;
        gap:100px;
        justify-content: space-between;
        margin-left: 180px;
    }
    .services-section{
        display: flex;
        align-items: center;
        gap:60px;
    }
    .profile-section{
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        right: 2%;
        .profile-icon{
            padding-right: 10px;

        }
        .profile-dropdown-icon{
            padding-left: 10px;
            font-size: 12px;
        }
    }
  
    button{
        background-color: transparent;
        border: none;
        color:white;
        font-size: 16px;
        cursor: pointer;
        padding:10px;
        border-radius: 10px;

    }
    button:hover,
    button.active{
        background-color: #c7c1c170;
    }
    .logo{
        position: relative;
        width: 170px;
        left:20px;
        height: auto;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Dashboard/MainPage.css"],"names":[],"mappings":"AAAA;;AAEA;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,WAAW;IACX,oDAAoD;IACpD;QACI,aAAa;QACb,mBAAmB;QACnB,SAAS;QACT,8BAA8B;QAC9B,kBAAkB;IACtB;IACA;QACI,aAAa;QACb,mBAAmB;QACnB,QAAQ;IACZ;IACA;QACI,aAAa;QACb,kBAAkB;QAClB,uBAAuB;QACvB,mBAAmB;QACnB,SAAS;QACT;YACI,mBAAmB;;QAEvB;QACA;YACI,kBAAkB;YAClB,eAAe;QACnB;IACJ;;IAEA;QACI,6BAA6B;QAC7B,YAAY;QACZ,WAAW;QACX,eAAe;QACf,eAAe;QACf,YAAY;QACZ,mBAAmB;;IAEvB;IACA;;QAEI,2BAA2B;IAC/B;IACA;QACI,kBAAkB;QAClB,YAAY;QACZ,SAAS;QACT,YAAY;IAChB;AACJ","sourcesContent":[".container{\n\n}\n.header-container{\n    display: flex;\n    align-items: center;\n    width:100%;\n    height:60px;\n    background: linear-gradient(45deg,  #2b1d4d,#2b1d4d);\n    .header-right-container{\n        display: flex;\n        align-items: center;\n        gap:100px;\n        justify-content: space-between;\n        margin-left: 180px;\n    }\n    .services-section{\n        display: flex;\n        align-items: center;\n        gap:60px;\n    }\n    .profile-section{\n        display: flex;\n        position: absolute;\n        justify-content: center;\n        align-items: center;\n        right: 2%;\n        .profile-icon{\n            padding-right: 10px;\n\n        }\n        .profile-dropdown-icon{\n            padding-left: 10px;\n            font-size: 12px;\n        }\n    }\n  \n    button{\n        background-color: transparent;\n        border: none;\n        color:white;\n        font-size: 16px;\n        cursor: pointer;\n        padding:10px;\n        border-radius: 10px;\n\n    }\n    button:hover,\n    button.active{\n        background-color: #c7c1c170;\n    }\n    .logo{\n        position: relative;\n        width: 170px;\n        left:20px;\n        height: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
