import React, { useEffect, useRef, useState } from 'react';
import { useAnimations, useFBX, useGLTF } from '@react-three/drei'; // Import Text from drei
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useThree,useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import config from '../config.json';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';



export default function ThreeDModelViewer({animationControl,onModelLoad}) {
    const [isPosition, setIsPosition] = useState([-2.2, -6, -1.5])
    const [isRotate, setIsRotation] =  useState([-Math.PI / 2, 0, Math.PI / 2])
    const { scene, camera } = useThree(); 
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    const roomRef = useRef();
    camera.position.set(-6.9684, 4.8051, 16.5458);
    const draco = new DRACOLoader()
    draco.setDecoderPath('https://threejs.org/examples/jsm/libs/draco/');
    
    useEffect(()=>{
        if(animationControl){
            setIsPosition([-2.2, -4, 4])
            setIsRotation([-Math.PI / 2, 0, -Math.PI / 10])
        }
    },[animationControl]);

    useEffect(() => {
        const loader = new GLTFLoader();
        loader.setDRACOLoader(draco)
 
        loader.load(`${config.backendAPI}room.glb`, (gltf) => {
            const room = gltf.scene;
            room.position.set(-3, -3, 1); 
            scene.add(room); 

            const directionalLight1 = new THREE.DirectionalLight(0xffffff, 3);
            directionalLight1.position.set(-2.2, -6, 3); // Light from the back left
            scene.add(directionalLight1);
            
            const directionalLight2 = new THREE.PointLight(0xffffff, 100);
            directionalLight2.position.set(-5, 0, 0); // Light from the back right
            scene.add(directionalLight2);

                    // Create a target for the light
            const lightTarget = new THREE.Object3D();
            lightTarget.position.set(-5, -5, 0); // Set the target position to control direction
            scene.add(lightTarget);

            directionalLight2.target = lightTarget;

            onModelLoad(true);
        })
                
        }, [scene]);

            useFrame(() => {
                if (animationControl && roomRef.current) {
                    roomRef.current.traverse((child) => {
                        if (child.isMesh) {
                            if (roomRef.current.scale.x > 0) {
                                roomRef.current.scale.x -= 0.0009;
                                roomRef.current.scale.y -= 0.0009;
                                roomRef.current.scale.z -= 0.0009;
                            } else {
                                child.visible = false; 
                            }
                        }
                    });
                }

            });
    
    const { nodes, materials } = useGLTF(`${config.backendAPI}standing.glb`);
    const typingCharacter = useGLTF(`${config.backendAPI}Typing.glb`);
    const disbeliefCharacter = useGLTF(`${config.backendAPI}Disbelief.glb`);
    typingCharacter.animations[0].name = "Typing";
    disbeliefCharacter.animations[0].name = "Disbelief";

    const group = useRef();
    const combinedAnimations = [...typingCharacter.animations, ...disbeliefCharacter.animations];
    const { actions } = useAnimations(combinedAnimations, group);

    useEffect(() => {
        const triangleGeometry = new THREE.ConeGeometry(1, 1, 3);
        const triangleMaterial = new THREE.MeshBasicMaterial({ color: 0xF9004D, wireframe: true });
        const triangle = new THREE.Mesh(triangleGeometry, triangleMaterial);
        triangle.position.set(-8, 5, -10);
        scene.add(triangle);
        const cubeGeometry = new THREE.BoxGeometry(1, 1, 1);
        const cubeMaterial = new THREE.MeshBasicMaterial({ color: 0xff6a00, wireframe: true });
        const cube = new THREE.Mesh(cubeGeometry, cubeMaterial);
        cube.position.set(-10, 5, -10); 
        scene.add(cube);
        const animateShapes = () => {
          triangle.rotation.x += 0.01;
          triangle.rotation.y += 0.01;
          cube.rotation.x += 0.03;
          cube.rotation.y += 0.03;
        };
        renderer.setAnimationLoop(animateShapes);
      }, [scene]);
      
      useEffect(() => {
        if (actions) {
            const playAnimationsInLoop = () => {
                if(animationControl !== true){
                    if (actions["Typing"]) {
                        actions["Typing"].reset().play();
                    }
                    const typingTimeout = setTimeout(() => {
                        if (actions["Typing"]) {
                            actions["Typing"].stop();
                        }
                        if (actions["Disbelief"]) {
                            actions["Disbelief"].reset().play();
                        }
                        const disbeliefTimeout = setTimeout(() => {
                            if (actions["Disbelief"]) {
                                actions["Disbelief"].stop();
                            }
                            playAnimationsInLoop();
                        }, 2000); 
                        timeouts.push(disbeliefTimeout);
                    }, 5000);
                    timeouts.push(typingTimeout);
            }
            else{
                if (actions["Sitting"]) {
                    actions["Sitting"].reset().play();
                }
            }
            };
            const timeouts = [];
            playAnimationsInLoop();
            return () => {
                timeouts.forEach(timeout => clearTimeout(timeout));
                if (actions["Typing"]) {
                    actions["Typing"].stop();
                }
                if (actions["Disbelief"]) {
                    actions["Disbelief"].stop();
                }
            };
        }
    }, [actions]);

    return (
        <>
            <group ref={group} dispose={null} scale={[5, 6.5, 6]} position={isPosition}>
                <group rotation={isRotate}>
                    <primitive object={nodes.Hips} />
                    <skinnedMesh geometry={nodes.Wolf3D_Hair.geometry} material={materials.Wolf3D_Hair} skeleton={nodes.Wolf3D_Hair.skeleton} />
                    <skinnedMesh geometry={nodes.Wolf3D_Glasses.geometry} material={materials.Wolf3D_Glasses} skeleton={nodes.Wolf3D_Glasses.skeleton} />
                    <skinnedMesh geometry={nodes.Wolf3D_Outfit_Top.geometry} material={materials.Wolf3D_Outfit_Top} skeleton={nodes.Wolf3D_Outfit_Top.skeleton} />
                    <skinnedMesh geometry={nodes.Wolf3D_Outfit_Bottom.geometry} material={materials.Wolf3D_Outfit_Bottom} skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton} />
                    <skinnedMesh geometry={nodes.Wolf3D_Outfit_Footwear.geometry} material={materials.Wolf3D_Outfit_Footwear} skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton} />
                    <skinnedMesh geometry={nodes.Wolf3D_Body.geometry} material={materials.Wolf3D_Body} skeleton={nodes.Wolf3D_Body.skeleton} />
                    <skinnedMesh name="EyeLeft" geometry={nodes.EyeLeft.geometry} material={materials.Wolf3D_Eye} skeleton={nodes.EyeLeft.skeleton} morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary} morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences} />
                    <skinnedMesh name="EyeRight" geometry={nodes.EyeRight.geometry} material={materials.Wolf3D_Eye} skeleton={nodes.EyeRight.skeleton} morphTargetDictionary={nodes.EyeRight.morphTargetDictionary} morphTargetInfluences={nodes.EyeRight.morphTargetInfluences} />
                    <skinnedMesh name="Wolf3D_Head" geometry={nodes.Wolf3D_Head.geometry} material={materials.Wolf3D_Skin} skeleton={nodes.Wolf3D_Head.skeleton} morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary} morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences} />
                    <skinnedMesh name="Wolf3D_Teeth" geometry={nodes.Wolf3D_Teeth.geometry} material={materials.Wolf3D_Teeth} skeleton={nodes.Wolf3D_Teeth.skeleton} morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary} morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences} />
                </group>
            </group>
           
        </>
    );
}
