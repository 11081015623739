/* test */
import React, { useEffect, useRef, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import ThreeDModelViewer from './Components/ThreeDModelViewer';
import ThreeDScreen2 from './Components/ThreeDScreen2';
import './App.css'; 
import logo from './background1.png';
import RingLoader from "react-spinners/RingLoader";
import CourseCard from './Components/CourseCard';
import config from './config.json';
import PrepGuru from './Components/PrepGuru';
import MockGuru from './Components/MockGuru';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

import ResumeGuru from './Components/ResumeGuru';
import JdGuru from './Components/JdGuru';
import CareerGuru from './Components/CareerGuru';
import PricingPlan from './Components/Pricing';
import Testimonials from './Components/Testimonials';
import LoginModal from './Components/LoginModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-regular-svg-icons';

const Main = () => {
  const [isLogin,setIsLogin] = useState(false);
  const screenRefs = useRef([
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const[startScreenTwoAnime, setStartScreenTwoAnime]=useState(false);
  const containerRef = useRef(null);
  
  const currentScreenRef = useRef(0);
  const [touchStartY, setTouchStartY] = useState(null);
  
  useEffect(() => {
    window.scrollTo(0, 0); // Initialize scroll position
  }, []);
  
  const handleScrollToScreen = (index) => {
    const targetScreen = screenRefs.current[index]?.current;
    if (targetScreen) {
      currentScreenRef.current = index;
      targetScreen.scrollIntoView({ behavior: 'smooth' });
    }
    if (index === 1) {
      setStartScreenTwoAnime(true);
    }
    else{
      setStartScreenTwoAnime(false);
    }
  };
  
  let lastScrollTime = 0;
  const throttleScroll = 800; // Throttle duration in ms
  
  // Unified scroll handler for wheel, touch, and key events
  const handleScroll = (direction) => {
    const now = Date.now();
    if (now - lastScrollTime < throttleScroll) return;
  
    const newIndex = currentScreenRef.current + direction;
    if (newIndex >= 0 && newIndex < screenRefs.current.length) {
      handleScrollToScreen(newIndex);
    }
    lastScrollTime = now;
  };
  
  const SCROLL_THRESHOLD = 50; // Set to a value that filters out minor scrolls

  const handleWheel = (event) => {
    event.preventDefault();
    // Only trigger scrolling if the absolute value of deltaY exceeds the threshold
    if (Math.abs(event.deltaY) > SCROLL_THRESHOLD) {
      handleScroll(event.deltaY > 0 ? 1 : -1);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') handleScroll(1);
    else if (event.key === 'ArrowUp') handleScroll(-1);
  };
    // Function to open the modal
    const openModal = () => {
      setIsLogin(true);
    };
  
    // Function to close the modal
    const closeModal = () => {
      setIsLogin(false);
    };
  
  const handleTouchStart = (event) => setTouchStartY(event.touches[0].clientY);
  const handleTouchEnd = (event) => {
    if (touchStartY === null) return;
    const swipeDistance = touchStartY - event.changedTouches[0].clientY;
    handleScroll(swipeDistance > 50 ? 1 : swipeDistance < -50 ? -1 : 0);
    setTouchStartY(null);
  };
  
  // Attach event listeners
  useEffect(() => {
    window.addEventListener('wheel', handleWheel);
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchend', handleTouchEnd);
    return () => {
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [touchStartY]);
  
  

  const textData = [
    { label: 'Name:', value: 'Botguru' },
    { label: 'Trained', value: '10k+ people' },
    { label: 'Sucess Ratio', value: '92%' }
  ];
  const videoRef = useRef(null);

  useEffect(() => {
    // Try to play the video when the component mounts
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error('Autoplay failed:', error);
      });
    }
  }, []);

  const handleModelLoad = (loaded) => {
    if (loaded) {
      setIsLoading(false);
    }
  };


  return (
    <div className="app-wrapper" ref={containerRef}>
       <LoginModal isLogin={isLogin} closeModal={closeModal} />
    {isLoading && (
      <div className="loading-screen">
        <RingLoader color="#ee0979" size={100} />
      </div>
    )}
    <div className="header" >
            <img className="logo" src={logo} alt="Logo" />
            <nav className="nav-menu">
              <button className = "main-menu" href="/">Home</button>
              <div className="dropdown">
                <button  className="main-menu dropdown-toggle">
                  Services
                  <span className="dropdown-icon">▼</span>
                </button>
                <div className="dropdown-menu">
                <button onClick={() => handleScrollToScreen(2)}>
                  <FontAwesomeIcon className ="dropdown-icons" icon={faEnvelope}></FontAwesomeIcon>
                  Prep Guru</button>
                <hr className="custom-line" />
                <button onClick={() => handleScrollToScreen(3)}>
                <FontAwesomeIcon className ="dropdown-icons" icon={faEnvelope}></FontAwesomeIcon>Mock Guru</button>
                <hr className="custom-line" />
                <button onClick={() => handleScrollToScreen(4)}> <FontAwesomeIcon className ="dropdown-icons" icon={faEnvelope}></FontAwesomeIcon>Resume Guru</button>
                <hr className="custom-line" />
                <button onClick={() => handleScrollToScreen(5)}> <FontAwesomeIcon className ="dropdown-icons" icon={faEnvelope}></FontAwesomeIcon>JD Guru</button>
                <hr className="custom-line" />
                <button  onClick={() => handleScrollToScreen(6)}> <FontAwesomeIcon className ="dropdown-icons" icon={faEnvelope}></FontAwesomeIcon>Career Guru</button>
                </div>
              </div>
              <button className = "main-menu" onClick={() => handleScrollToScreen(7)}>Pricing</button>
              {/* <button  className = "main-menu" href="/Contactus">Contact Us</button> */}
              {/* <Link to="/login" className="btn btn-primary">Login</Link> */}
            </nav>
            <nav className='loginbutton'>
              <button className="btn btn-primary" onClick={openModal}>
                Get Started <img className = "play" src={'play.svg'} alt="play"></img></button>
            </nav>
      </div>
      <div className="screen screen1" ref={screenRefs.current[0]}>
      <div className='content'>
        <div className="content-2d">
          <div className="container">
            <div className="text-wrapper">
              <h1 className="three-d-text1">
                <b>Stressed and Anxious about </b>
                <b className="three-d-text">Job Interview prep?</b>
              </h1>
              <p className="p-three-d-text">
              Look no further! BotGuru empowers job seekers with cutting-edge AI tools—<strong>Prep Guru and Mock Guru</strong>. Receive personalized coaching, tailored practice questions, and realistic interview simulations. Boost your success rate by 90% with instant feedback and detailed custom reports!
              </p>
              <button className="button-3d btn-primary">Subscribe</button>
                <div className="stage-subclass">
                  <div className="badge">10k+ users |  30k+ sessions</div>
              </div>
              <div className="video-card">
                <div className="video-wrapper">
                    <video
                      ref={videoRef}
                      src={`${config.backendAPI}testvideo.mp4`}
                      autoPlay
                      loop
                      muted
                      playsInline
                      style={{ width: '80%', borderRadius: '10px' }}
                    ></video>
                  <div className="price-tag">
                    <span className="price">$7</span>
                    <span className="description">5 sessions, VAT incl.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-3d">
          <Canvas style={{ pointerEvents: 'auto' }}>
            <ambientLight intensity={0.5} />
            <pointLight position={[10, 10, 10]} />
            <ThreeDModelViewer onModelLoad={handleModelLoad} />
            <OrbitControls enableZoom={false} />
          </Canvas>
        </div>
      </div>
      </div>

      <div className="screen screen2" ref={screenRefs.current[1]}>
        <div className="screen2-content-2d">
        <div className="profile">
        <svg width="200" height="230">
          <defs>
            <clipPath id="polygon-clip">
              <polygon points="65,170 145,170 145,50 135,40 135,10 125,0 70,0 10,0 0,10 0,160 10,170" transform="scale(1.15) translate(10,0)" />
            </clipPath>
          </defs>
          <image
            href="botguruBG.png"
            width="180"
            height="220"
            clip-path="url(#polygon-clip)"
            preserveAspectRatio="xMidYMid slice"
          />
          <polygon
            points="65,170 145,170 145,50 135,40 135,10 125,0 70,0 10,0 0,10 0,160 10,170"
            transform="scale(1.15) translate(10,0)"
            fill="none"
            stroke="black"
            stroke-width="2"
          />
        </svg>
            <svg width="800" height="300" style={{ position: 'absolute' }}>
              <polygon points="145,75 250,75 260,85 560,85 570,105 570,135 570,150 560,160 200,160 190,170 145,170" transform="scale(1.15) translate(10,0)" />
              {textData.map((item, index) => (
                    <g key={index} transform={`translate( ${200 + index * 170},130)`}>
                      <text
                        className="text"
                      >
                        {item.label}
                      </text>
                      <text
                        className="subtext"
                        y="26"
                      >
                        {item.value}
                      </text>
                    </g>
                  ))}
            </svg>

        
          </div>
          <div className="course-card-container">
            <CourseCard animationControl={startScreenTwoAnime}
            />
          </div>
        </div>
        <div className="screen2-content-3d">
          <Canvas style={{ pointerEvents: 'auto' }}>
            <ambientLight intensity={0.5} />
            <pointLight position={[10, 10, 10]} />
            <ThreeDScreen2  animationControl={startScreenTwoAnime} />
            <OrbitControls enableZoom={false} />
          </Canvas>
        </div> 
      </div>
      <div className="screen screen3" ref={screenRefs.current[2]} id="prepGuru">
        <PrepGuru></PrepGuru>
      </div>
      <div className="screen screen4" ref={screenRefs.current[3]} id = 'mockGuru'>
        <MockGuru></MockGuru>
      </div>
      <div className="screen screen5" ref={screenRefs.current[4]} id='resumeGuru'>
        <ResumeGuru></ResumeGuru>
      </div>
      <div className="screen screen6" ref={screenRefs.current[5]} id='jdGuru'>
        <JdGuru></JdGuru>
      </div>
      <div className="screen screen7" ref={screenRefs.current[6]} id='careerGuru'>
        <CareerGuru></CareerGuru>
      </div>
      <div className="screen screen8" ref={screenRefs.current[7]} id='pricing'>
        <PricingPlan></PricingPlan>
      </div>
      <div className="screen screen9" ref={screenRefs.current[8]}  id='pricing'>
        <Testimonials></Testimonials>
      </div>
    </div>
  );
};

export default Main;
