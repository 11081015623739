import React,{useState} from 'react';
import './MainPage.css'; 
import logo from '../../logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';


const MainPage = () => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(null); 
  const handleButtonClick = (buttonName, route) => {
    setActiveButton(buttonName);
    navigate(route);
  };

  return (
      <div className="header-container">
        <img className="logo" src={logo} alt="Logo" />
        <div className='header-right-container'>
        <div className="services-section">
            <button className={activeButton === 'Dashboard' ? 'active' : ''} onClick={() => handleButtonClick('Dashboard', '/dashboard')}>Dashboard</button>
            <button className={activeButton === 'PrepGuru' ? 'active' : ''}  onClick={() => handleButtonClick('PrepGuru', '/prepgurumain')}>PrepGuru</button>
            <button className={activeButton === 'MockGuru' ? 'active' : ''} onClick={() => handleButtonClick('MockGuru', '/mockgurumain')}>MockGuru</button>
            <button className={activeButton === 'ResumeGuru' ? 'active' : ''}  onClick={() => handleButtonClick('ResumeGuru', '/resumegurumain')}>ResumeGuru</button>
            <button className={activeButton === 'JDGuru' ? 'active' : ''} onClick={() => handleButtonClick('JDGuru', '/jdgurumain')}>JDGuru</button>
            <button className={activeButton === 'CareerGuru' ? 'active' : ''}  onClick={() => handleButtonClick('CareerGuru', '/careergurumain')}>CareerGuru</button>
        </div>
        <div className='profile-section'>
          <button>
            <FontAwesomeIcon  className="profile-icon" icon={faUser}>
              </FontAwesomeIcon>Jahnavi Yannam
               <span className="profile-dropdown-icon">▼</span></button>
        </div>
        </div>
      </div>
      
  );
};

export default MainPage;
