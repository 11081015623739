import React,{useState} from "react";
import './CreateSessionModal.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Tabs, Tab, TabList } from 'react-tabs';  


const CreateSessionModal = ({isOpen, closeModal})=>{
    const [activeTab, setActiveTab] = useState(0);

    const handleTabSelect = (index) => {
        setActiveTab(index === 0 ? 0 : 1);
      };
    if (!isOpen) return null;
    return(
        <div className="modal-overlay"  onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeModal}>&times;</button>
            <h2>Start Your Next Interview</h2>
            <div>
                <div className="upload-resume-div">
                    <label className='resume'>Resume</label>
                    <div className="upload-resume-buttons">
                        <button className='button new' >+ Create new</button>
                        <button className="button upload" onClick={() => document.getElementById('fileInput').click()}> <i class="fas fa-arrow-up"></i> Upload</button>
                        <input
                            id="fileInput"
                            type="file"
                            style={{ display: 'none' }}
                        />
                    </div>
                </div>
                <select >
                <option>Select your Resume</option>
                </select>
            </div>
            <div>
            <div className="upload-resume-div">
                    <label className='resume'>Job Description</label>
                    <div className="upload-resume-buttons">
                        <button className='button new' >+ Create new</button>
                        <button className="button upload" onClick={() => document.getElementById('fileInput').click()}> <i class="fas fa-arrow-up"></i> Upload</button>
                        <input
                            id="fileInput"
                            type="file"
                            style={{ display: 'none' }}
                        />
                       
                    </div>
                </div>
            
            <select>
              <option>Select your Job description</option>
            </select>
            </div>
            <div>
            <label>
              Select Knowledge Domain(Specialization) <span>Optional</span>
            </label>
            <select>
              <option>General</option>
              <option>Technical</option>
              <option>Management</option>
            </select>
            </div>
 
          <div>
            <label>
              Schedule your Interview 
            </label>
            <div className="prep-bot-status-tabs">
                <Tabs onSelect={handleTabSelect}>
                <TabList>
                    <Tab>Instant</Tab>
                    <Tab>Schedule</Tab>
                </TabList>
                </Tabs>
            </div>
          </div>
          {activeTab===0 && <div className="footer">
           
          <button className="footer-button submit">Start Session</button>
          <button  className="footer-button cancel">Cancel</button>

          </div>}
          {activeTab===0 && <div>
          
          </div>}
          </div>
        </div>
    )

}
export default CreateSessionModal;