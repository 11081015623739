import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import './Pricing.css';
import pricingData from './data/data.json';



const PricingPlan = () => {
  const changePrice = (newSessionNumber) => {
    setSessionNumber(newSessionNumber);
    setCustomPrice(newSessionNumber * 5.99);
  };
  const [customPrice, setCustomPrice] = useState(0);
  const [sessionNumber, setSessionNumber] = useState(0);
  return (
    <>
      <div className="with-black-border">
        <div className="pricing-container">
          <div className="section-title">
            {/* <span className="sub-title">PRICING PLAN</span> */}
            <h2 className="text-white">Choose Your Plan</h2>
          </div>

          <div className="saas-pricing-tabs">
            <Tabs>
              <TabList>
                <Tab>Mock Bot</Tab>
                <Tab>Prep Bot</Tab>
                <Tab>Expert Resume Builder</Tab>
                <Tab>AI JD Generator</Tab>
              </TabList>

              <TabPanel>
                <div className="all-cards custom-animation">
                  {pricingData.basicPricingData &&
                    pricingData.basicPricingData.slice(0, 3).map((value, i) => (
                      <div className="col-lg-4 col-md-6 col-sm-6" key={i}>
                        {value.title == "Custom Pricing" && (
        
                          <div
                            className="saas-pricing-box saas-color custom-animation"
                          >
                            <div className="pricing-header">
                              <h3>{value.title}</h3>
                              <span className="pricing">
                                ${customPrice.toFixed(2)}
                                <span>{value.duration}</span>
                              </span>
                            </div>


                            <ul className="features-list">
                            <li className={value.unactiveCalss} key={i}>
                            <div className="counter-container">
                                  <button
                                    onClick={() => changePrice(Math.max(sessionNumber - 1, 0))}
                                    className="counter-btn decrement"
                                  >
                                    <span>-</span>
                                  </button>
                                  <span className="counter-display">{sessionNumber}</span>
                                  <button
                                    onClick={() => changePrice(sessionNumber + 1)}
                                    className="counter-btn increment"
                                  >
                                    <span>+</span>
                                  </button>
                                  <span> sessions</span>
                                </div>
                                </li>
                              {value.lists.map((value, i) => (
                                <li className={value.unactiveCalss} key={i}>
                                  {value.title}
                                </li>
                              ))}
                            </ul>

                            <a
                              href={value.btnLink}
                              className="default-btn-two saas-color"
                            >
                              {value.btnText}{" "}
                              <i className="fas fa-chevron-right"></i>
                            </a>
                          </div>
                        )}

                        {value.title != "Custom Pricing" && (
                          <div
                            className="saas-pricing-box saas-color custom-animation"
                          >
                            <div className="pricing-header">
                              <h3>{value.title}</h3>
                              <span className="pricing">
                                ${value.price}
                                <span>{value.duration}</span>
                              </span>
                            </div>

                            <ul className="features-list">
                              {value.lists.map((value, i) => (
                                <li className={value.unactiveCalss} key={i}>
                                  {value.title}
                                </li>
                              ))}
                            </ul>

                            <a
                              href={value.btnLink}
                              className="default-btn-two saas-color"
                            >
                              {value.btnText}{" "}
                              <i className="fas fa-chevron-right"></i>
                            </a>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </TabPanel>

              <TabPanel>
              <div className="all-cards">
                  {pricingData.premiumPricingData &&
                    pricingData.premiumPricingData.slice(0, 3).map((value, i) => (
                      <div className="col-lg-4 col-md-6 col-sm-6" key={i}>
                        <div
                          className="saas-pricing-box saas-color custom-animation"
                        >
                          <div className="pricing-header">
                            <h3>{value.title}</h3>
                            <span className="pricing">
                              ${value.price}
                              <span>{value.duration}</span>
                            </span>
                          </div>

                          <ul className="features-list">
                            {value.lists.map((value, i) => (
                              <li className={value.unactiveCalss} key={i}>
                                {value.title}
                              </li>
                            ))}
                          </ul>

                          <a
                            href={value.btnLink}
                            className="default-btn-two saas-color"
                          >
                            {value.btnText}{" "}
                            <i className="fas fa-chevron-right"></i>
                          </a>
                        </div>
                      </div>
                    ))}
                </div>
              </TabPanel>
              <TabPanel>
              <div className="all-cards">
                  {pricingData.ResumeBuilderGenerator &&
                    pricingData.ResumeBuilderGenerator.slice(0, 3).map((value, i) => (
                      <div className="col-lg-4 col-md-6 col-sm-6" key={i}>
                        <div
                          className="saas-pricing-box saas-color custom-animation"
                        >
                          <div className="pricing-header">
                            <h3>{value.title}</h3>
                            <span className="pricing">
                              ${value.price}
                              <span>{value.duration}</span>
                            </span>
                          </div>

                          <ul className="features-list">
                            {value.lists.map((value, i) => (
                              <li className={value.unactiveCalss} key={i}>
                                {value.title}
                              </li>
                            ))}
                          </ul>

                          <a
                            href={value.btnLink}
                            className="default-btn-two saas-color"
                          >
                            {value.btnText}{" "}
                            <i className="fas fa-chevron-right"></i>
                          </a>
                        </div>
                      </div>
                    ))}
                </div>
              </TabPanel>
              <TabPanel>
              <div className="all-cards">
                  {pricingData.JDGeneratorPlans &&
                    pricingData.JDGeneratorPlans.slice(0, 3).map((value, i) => (
                      <div className="col-lg-4 col-md-6 col-sm-6" key={i}>
                        <div
                          className="saas-pricing-box saas-color custom-animation"
                        >
                          <div className="pricing-header">
                            <h3>{value.title}</h3>
                            <span className="pricing">
                              ${value.price}
                              <span>{value.duration}</span>
                            </span>
                          </div>

                          <ul className="features-list">
                            {value.lists.map((value, i) => (
                              <li className={value.unactiveCalss} key={i}>
                                {value.title}
                              </li>
                            ))}
                          </ul>

                          <a
                            href={value.btnLink}
                            className="default-btn-two saas-color"
                          >
                            {value.btnText}{" "}
                            <i className="fas fa-chevron-right"></i>
                          </a>
                        </div>
                      </div>
                    ))}
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingPlan;
