import React,{useState,useRef, useEffect} from 'react';
import './LoginModal.css';
import PhoneInput from "react-phone-number-input";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import 'react-phone-number-input/style.css'




const LoginModal = ({ isLogin, closeModal }) => {
    const [email, setEmail] = useState("");
    const [showTooltip, setShowTooltip] = useState(false);
    const [shownSignIn, setShowSignIn] = useState(true)
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [showDetailsForm, setShowDetailsForm] = useState(false);
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [isFocused, setIsFocused] = useState(false); // Add focus state
    const[flag,setFlag]=useState(false);
  

    const [details, setDetails] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
      });


    const clientId = "583854496467-v8mtviku2cd2ffbmi29r4tbg9dnooul5.apps.googleusercontent.com"; // Replace with your actual client ID


    const handleLoginSuccess = (response) => {
      console.log("Google Sign-In Success:", response);
      // Here, you can send the response token to your backend for verification
    };
  
    const handleLoginFailure = (error) => {
      console.log("Google Sign-In Failed:", error);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
      };
      const handleEmailSubmit = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            setShowTooltip(true)
        }
        else{
            setShowSignIn(false);
            setShowTooltip(false);
            setShowOtpInput(true);
            setShowDetailsForm(false);
        }
        
        // } else {
        //   setLoading(true);
        //   setTimeout(() => {
        //     setLoading(false);
        //     setIsEmailDisabled(true);
        //     setShowOtpInput(true);
        //   }, 1000);
        // }
      };
      const inputRefs = useRef([]);

      const handleOtpSubmit = () => {
        const otpValue = otp.join("");
        if (otpValue === "000000") {
          setTimeout(() => {
            setShowOtpInput(false);
            setShowSignIn(false)
            setShowDetailsForm(true);
          }, 1000);
        } else {
          setFlag(true)
        }
      };
      const handleKeyDown = (element, index) => {
        if (element.key === "Backspace" && otp[index] === "") {
          if (index > 0) {
            document.getElementById(`otp-input-${index - 1}`).focus();
          }
        }
     
        if (element.key === "Enter" && index === 5 && otp[index] !== "") {
          handleOtpSubmit();
        }
      };
      const handleOtpChange = (element, index) => {
        if (/^[0-9]$/.test(element.value) || element.value === "") {
          const otpCopy = [...otp];
          otpCopy[index] = element.value;
          setOtp(otpCopy);
          if (element.value !== "" && index < 5) {
            document.getElementById(`otp-input-${index + 1}`).focus();
          }
        }
      };
      const handleDetailsChange = (e) => {
        setDetails({
          ...details,
          [e.target.name]: e.target.value,
        });
      };
     
      const handlePhoneChange = (value) => {
        setDetails((prevDetails) => ({
          ...prevDetails,
          phoneNumber: value,
        }));
      };

      const handleDetailsSubmit = () => {
        
      };
  if (!isLogin) return null; 

  return (
    <div className="modal">
      <div className="modal-content">
      <button className="close-button" onClick={closeModal}>×</button>
        <div className="left-modal-content">
                <img src={'logincard.jpg'} alt="Logo" />
        </div>
        {shownSignIn && !showOtpInput && <div className="right-modal-content">
            <h1>Sign In</h1>
            <p>Access to Mock bot, Prep Bot, Resume Builder and many other.</p>
            <div className='email-container'>
                <input type="email" 
                className={showTooltip ? 'error' : ''}
                placeholder="Email address"
                value={email}
                onChange={handleEmailChange}
                 /> 
                <FontAwesomeIcon className="input-icon" icon={faEnvelope} />
                </div>
                {showTooltip && (
                <span className="tooltip">
                    <img className = "warning" src={'warning.svg'} alt="Warning"></img>
                    Please enter a valid email address.
                </span>
            )}
            <div><button className="sign-in" onClick = {handleEmailSubmit}>Sign In</button></div>
            <hr></hr>            
           
            <br></br>
            <GoogleOAuthProvider clientId={clientId}>
      
            <GoogleLogin
                onSuccess={handleLoginSuccess}
                onError={handleLoginFailure}
                useOneTap
                theme="filled_blue"  
                shape="pill"     
                width="220px" 
                render={(renderProps) => (
                    <button
                    className="login-with-google-btn"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    >
                    <img src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" alt="Google logo" />
                    Sign in with Google
                    </button>
                )}
                />
            </GoogleOAuthProvider>
        </div>}

        {showOtpInput &&  !showDetailsForm && !shownSignIn&& (
          <div className="right-modal-content">
            <div className="otp-header">
                <div>
                    <FontAwesomeIcon className="otp-icon" icon={faEnvelope} />
                </div>
                <h2>
                    Verify Your Email
                </h2>
                <span>Please Enter the Verification code we sent to <strong>{email}</strong> 
                <button className="change-email">Change email</button>
                </span>
                <br></br>
              
                <span>
                  {/* <Link to="/login">Change!</Link> */}
                </span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "5px",
                  margin: "20px",
                }}
              >
                {otp.map((data, index) => (
                  <input
                    key={index}
                    type="text"
                    id={`otp-input-${index}`}
                    name="otp"
                    maxLength="1"
                    className="otp-field"
                    value={data}
                    onChange={(e) => handleOtpChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onFocus={(e) => e.target.select()}
                    ref={(el) => (inputRefs.current[index] = el)}
                    style={{
                      width: "40px",
                      height: "40px",
                      textAlign: "center",
                      fontSize: "20px",
                    }}
                  />
                ))}
              </div>
              <span>OTP not received? 
                <button className = "change-email">Resend</button></span>
              {/* <div className="otp-error">Wrong OTP! Please Try Again</div> */}
              <div className='confirm'>
              <button className="btn btn-primary" onClick={handleOtpSubmit}>
              Confirm OTP
            </button>
            </div>
            </div>
          </div>
        )}

        {showDetailsForm && !showOtpInput && !shownSignIn && (
          <div className="right-modal-content">
          <div className="details-box">
            <h1>Registration</h1>
            <form className="details-form login-form">
                <div className='email-container'>
                    <input
                    type="name" 
                    name="first-name"
                    className={showTooltip ? 'error' : ''}
                    placeholder="First Name"
                    onChange={handleDetailsChange}
                    required
                    /> 
                </div>
                <div className='email-container'>
                    <input
                    type="name" 
                    name="last-name"
                    className={showTooltip ? 'error' : ''}
                    placeholder="Last Name"
                    onChange={handleDetailsChange}
                    required
                    /> 
                </div>
              
              
                <div className='email-container'>
                    <input type="emailname" 
                    placeholder='Email address'
                    className={showTooltip ? 'error' : ''}
                    value={email}
                    onChange={handleEmailChange}
                    /> 
                    <FontAwesomeIcon className="input-icon" icon={faEnvelope} />
                </div>
                <div className={`phone-container ${isFocused ? 'focused' : ''}`}>
                    <PhoneInput
                        type="phone"
                        className=""
                        placeholder="Phone Number"
                        name="Phone-Number"
                        onFocus={() => setIsFocused(true)}
                        value={details.phoneNumber}
                        onChange={handlePhoneChange}
                        required
                    />

                </div>
                <div className='confirm'>
                <button
                    className="btn btn-primary"
                    onClick={handleDetailsSubmit}
                >
                    Submit Details
                </button>
                </div>
            </form>
          </div>
          </div>
        )}
        
       
      </div>
    </div>
  );
};

export default LoginModal;
