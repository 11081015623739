import React, { useEffect, useRef, useMemo} from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useThree,useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import config from '../config.json';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
 


export default function ThreeDScreen2({animationControl}) {
    const modelRef = useRef(null); 
    const barRef = useRef();
    const barRefs = [];
    const { scene } = useThree(); 
    const hollowCylinderRef = useRef(); 
    const speed = 0.5; 
    const amplitude = 2.3;
    const draco = new DRACOLoader()
    draco.setDecoderPath('https://threejs.org/examples/jsm/libs/draco/');


    const lights = useMemo(() => {
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      const pointLight = new THREE.PointLight(0xffffff, 1.5);
      pointLight.position.set(2, 5, 5);
      const directionalLight1 = new THREE.DirectionalLight(0xffffff, 3);
      directionalLight1.position.set(-1.6, 12, -1);
      const directionalLight2 = new THREE.DirectionalLight(0xffffff, 3);
      directionalLight2.position.set(-1.6, -8, -1);
      const directionalLight3 = new THREE.DirectionalLight(0xffffff, 3);
      directionalLight3.position.set(-20, -2, 15);
      return [ambientLight, pointLight, directionalLight1, directionalLight2, directionalLight3];
  }, []);
  
  useEffect(() => {
    lights.forEach(light => scene.add(light));
    return () => {
        lights.forEach(light => scene.remove(light));
    };
}, [lights, scene]);


    useEffect(()=>{
      const bubbles = [];
      
      const textureLoader = new THREE.TextureLoader();
      const imagePaths = [
          'icons/python.png',
          'icons/mySql.png',
          'icons/doctors.png',
          'icons/electronics.png',
          'icons/banker.png',
          'icons/chef.png',
          'icons/medical.png',
          'icons/lawyer.png',
          'icons/teacher.png',
      ];

    //   imagePaths.forEach((path, index) => {
    //     textureLoader.load(path, (texture) => {
    //         const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true });
    //         const geometry = new THREE.PlaneGeometry(0.8, 0.8);
    //         const mesh = new THREE.Mesh(geometry, material);

    //         // Set random positions
    //         const angle = Math.random() * Math.PI * 2;
    //         const radius = Math.random() + 0.82;
    //         mesh.position.set(
    //             Math.cos(angle) * radius*2.55,
    //             index +Math.cos(angle) * radius*0.35,
    //             -2 +Math.cos(angle) * radius*0.25
    //         );

    //         scene.add(mesh);
    //         bubbles.push(mesh);
    //         animateBubbles();
    //         animateBubbles();
    //     });
    // });

    // imagePaths.forEach((path, index) => {
    //     textureLoader.load(path, (texture) => {
    //         const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true });
    //         const geometry = new THREE.PlaneGeometry(0.5, 0.5);
    //         const mesh = new THREE.Mesh(geometry, material);

    //         // Set random positions
    //         const angle = Math.random() * Math.PI * 9;
    //         const radius = Math.random() + 0.82;
    //         mesh.position.set(
    //             -1+Math.cos(angle) * radius,
    //             index * 0.3,
    //             -1+ 2*(radius+0.3)
    //         );

    //         scene.add(mesh);
    //         bubbles.push(mesh);
    //         animateBubbles();
    //     });
    // });
        
        const animateBubbles = () => {
            requestAnimationFrame(animateBubbles);
            bubbles.forEach((textMesh) => {
                textMesh.position.y += 0.0009;
                if (textMesh.position.y > 3.8) {
                    textMesh.position.y = -2;
                }
            });
                };
        
        const capsule = new THREE.Group();

        
        const cylinderGeometry = new THREE.CylinderGeometry(1.30, 1.30, 5.25, 32);
            const cylinderMaterial = new THREE.MeshStandardMaterial({
            color: 0xffffff,
            opacity: 0.09,
            transparent: true,
            side: THREE.DoubleSide,
            });
            const cylinder = new THREE.Mesh(cylinderGeometry, cylinderMaterial);
            cylinder.position.set(-3.5,0,-1)
            capsule.add(cylinder);



            const cylinder1Geometry = new THREE.CylinderGeometry(1.5, 1.5, 0.15, 32, 1, true);
            const cylinder1Material = new THREE.MeshStandardMaterial({
            color: 0xffffff,
            side: THREE.DoubleSide,
            transparent: true,
            opacity: 0.5,
            });
        
            const hollowCylinder = new THREE.Mesh(cylinder1Geometry, cylinder1Material);
            hollowCylinder.position.set(-3.5,0,-1)
            hollowCylinderRef.current = hollowCylinder; // Store the reference

            capsule.add(hollowCylinder);
        
            // Create top and bottom bases
            const baseGeometry = new THREE.CylinderGeometry(1.4, 1.4, 0.6, 64);
            const baseMaterial = new THREE.MeshStandardMaterial({ color: 0xACACAC});
        
            const baseTop = new THREE.Mesh(baseGeometry, baseMaterial);
            baseTop.position.set(-3.5, 2.70, -1);

            capsule.add(baseTop);
        
            const baseGeometry1 = new THREE.CylinderGeometry(1.4, 1.4, 0.65, 64);
            const baseMaterial1 = new THREE.MeshStandardMaterial({ color: 0xACACAC, metalness: 0.1, roughness: 0.1 });
            const baseBottom = new THREE.Mesh(baseGeometry1, baseMaterial1);
            baseBottom.position.set(-3.5, -2.75, -1);

            capsule.add(baseBottom);
            capsule.position.set(0.5,-0.2,0.5)
            scene.add(capsule);
            const loader = new GLTFLoader();
            loader.setDRACOLoader(draco)
 
            loader.load(
              `${config.backendAPI}computer.glb`,
              (gltf) => {
                const model = gltf.scene;
                model.position.set(1, -3, 0.6);
                model.scale.set(0.65, 0.6, 0.6);
                model.rotation.set(0, 2.5, 0);
                scene.add(model);
              },
              undefined,
              (error) => {
                console.error('Error loading GLTF model:', error);
              }
            );
            const loader1 = new GLTFLoader();
            loader1.load(
              `${config.backendAPI}pipe.glb`,
              (gltf) => {
                const model = gltf.scene;
                model.position.set(-1, -2.5,-0.4);
                model.scale.set(2.5, 2.5, 2.5);
                model.rotation.set(-10, 0.5,0.3);
                scene.add(model);
              },
              undefined,
              (error) => {
                console.error('Error loading GLTF model:', error);
              }
            );
            const barGeometry = new THREE.BoxGeometry(0.5, 0.15, 0.05);
            const barMaterial = new THREE.MeshStandardMaterial({ color: 0xf03c94 });

            // Define the positions for each bar
            const barPositions = [1.25, 0.92, 0.62, 0.35];

            // Loop to create and add bars to the scene
            barPositions.forEach((yPosition) => {
                const bar = new THREE.Mesh(barGeometry, barMaterial);
                bar.position.set(0.2, yPosition, -0.58); // Use the y position from the array
                bar.rotation.set(0.1, -0.7, 0.1);
                scene.add(bar);
                barRefs.push(bar); // Store references to the bars
            });
            barRef.current = barRefs;

                        },[scene])


            useFrame(() => {
                const maxScale = 10; 
                const resetScaleThreshold = 3;
                const barSpeeds = [0.009, 0.002, 0.010, 0.015];
                barRef.current.forEach((bar, index) => {
                    if (bar) {
                        // Use the speed corresponding to the current bar
                        const barSpeed = barSpeeds[index % barSpeeds.length]; // Cycle through speeds if there are more bars than speeds
                        
                        bar.scale.x = Math.min(bar.scale.x + barSpeed, maxScale);
                        if (bar.scale.x >= resetScaleThreshold) {
                            bar.scale.x = 0.1; 
                        }
                        bar.position.x = 0.25 + bar.scale.x * 0.2;
                        bar.position.z = -0.60 + bar.scale.x * 0.2;
                    }
                });
            });
            
            

            
            
            
            useFrame(({ clock }) => {
                // Animate the up and down motion of the hollow cylinder
                if (hollowCylinderRef.current) {
                    const time = clock.getElapsedTime();
                    hollowCylinderRef.current.position.y = Math.sin(time * speed) * amplitude;
                }
            });

            useEffect(() => {
              const loader = new GLTFLoader();
              loader.setDRACOLoader(draco)
              let model;
          
              if (animationControl) {
                loader.load(
                  `${config.backendAPI}semiformal.glb`,
                  (gltf) => {
                    model = gltf.scene;
                    model.position.set(-2.2, 6, 1);
                    model.rotation.set(0, 0.5, 0);
                    model.scale.set(2.2, 2.4, 2.4);
                    modelRef.current = model; // Store the model in the ref
                    scene.add(model);
          
                    const targetPosition = new THREE.Vector3(-3, -2.5, -0.5);
                    const duration = 2000;
                    const startTime = performance.now();
          
                    function animateModel() {
                      const elapsed = performance.now() - startTime;
                      const progress = Math.min(elapsed / duration, 1);
                      model.position.lerpVectors(model.position, targetPosition, progress);
          
                      if (progress < 1) {
                        requestAnimationFrame(animateModel);
                      }
                    }
          
                    animateModel();
                  },
                  undefined,
                  (error) => {
                    console.error('Error loading GLTF model:', error);
                  }
                );
              } else {
                if (modelRef.current) {
                  scene.remove(modelRef.current);
                  modelRef.current = null; 
                }
              }
              return () => {
                if (modelRef.current) {
                  scene.remove(modelRef.current);
                  modelRef.current = null;
                }
              };
            }, [animationControl]);

    return (
        <>
           
        </>
    );
    }
